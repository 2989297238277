import client from '@/lib/api/client';
import {
  apiV1LargeCategoryMiddleCategoriesPath,
  apiV1LargeCategoryMiddleCategoryQuestionSubjectsPath,
} from '@/lib/rails-routes';

type MiddleCategory = {
  id: number,
  name: string,
}

type QuestionSubject = {
  id: number,
  name: string,
}

const getMiddleCategories = async (largeCategoryId: string): Promise<MiddleCategory[]> => {
  const {
    data,
  } = await client.get(apiV1LargeCategoryMiddleCategoriesPath({ largeCategoryId }));
  return data;
};

const getQuestionSubjects = async (
  largeCategoryId: string, middleCategoryId: string,
): Promise<QuestionSubject[]> => {
  const {
    data,
  } = await client.get(
    apiV1LargeCategoryMiddleCategoryQuestionSubjectsPath({ largeCategoryId, middleCategoryId }),
  );
  return data;
};

export { getMiddleCategories, getQuestionSubjects };
